/*================================================================================================================
*                                                  Rights
* ==============================================================================================================*/

// users
export const GET_USER_KEY = 'user';
export const GET_USERS_KEY = 'users';
export const UPSERT_USERS_KEY = 'upsertUser';
export const LINK_USER_GOOGLE_ACCOUNT = 'linkUserGoogleAccount';
export const UNLINK_USER_GOOGLE_ACCOUNT = 'unlinkUserGoogleAccount';
export const LOGOUT_USERS_KEY = 'logoutUsers';
export const LOGOUT_ALL_USERS_KEY = 'logoutAllUsers';
export const DELETE_USER_KEY = 'deleteUser';

// rights
export const GET_RIGHTS_KEY = 'rights';
export const UPSERT_RIGHT_KEY = 'upsertRight';
export const DELETE_RIGHT_KEY = 'deleteRight';

// roles
export const GET_ROLES_KEY = 'roles';
export const UPSERT_ROLE_KEY = 'upsertRole';
export const DELETE_ROLE_KEY = 'deleteRole';


/*================================================================================================================
*                                                  Master Data
* ==============================================================================================================*/

// Camp
export const GET_CAMPS_KEY = 'camps';
export const UPSERT_CAMP_KEY = 'upsertCamp';
export const DELETE_CAMP_KEY = 'deleteCamp';

// Gender
export const GET_GENDERS_KEY = 'genders';
export const UPSERT_GENDER_KEY = 'upsertGender';
export const DELETE_GENDER_KEY = 'deleteGender';

// CommentType
export const GET_COMMENT_TYPES_KEY = 'commentTypes';
export const UPSERT_COMMENT_TYPE_KEY = 'upsertCommentType';
export const DELETE_COMMENT_TYPE_KEY = 'deleteCommentType';

// Region
export const GET_REGIONS_KEY = 'regions';
export const UPSERT_REGION_KEY = 'upsertRegion';
export const DELETE_REGION_KEY = 'deleteRegion';

// Herd
export const GET_HERDS_KEY = 'herds';
export const UPSERT_HERD_KEY = 'upsertHerd';
export const DELETE_HERD_KEY = 'deleteHerd';

// EyeColour
export const GET_EYE_COLOURS_KEY = 'eyeColours';
export const UPSERT_EYE_COLOUR_KEY = 'upsertEyeColour';
export const DELETE_EYE_COLOUR_KEY = 'deleteEyeColour';

// TickType
export const GET_TICK_TYPES_KEY = 'tickTypes';
export const UPSERT_TICK_TYPE_KEY = 'upsertTickType';
export const DELETE_TICK_TYPE_KEY = 'deleteTickType';

// TickLevel
export const GET_TICK_LEVELS_KEY = 'tickLevels';
export const UPSERT_TICK_LEVEL_KEY = 'upsertTickLevel';
export const DELETE_TICK_LEVEL_KEY = 'deleteTickLevel';

// SupplementaryFeedingType
export const GET_SUPPLEMENTARY_FEEDING_TYPES_KEY = 'supplementaryFeedingTypes';
export const UPSERT_SUPPLEMENTARY_FEEDING_TYPE_KEY = 'upsertSupplementaryFeedingType';
export const DELETE_SUPPLEMENTARY_FEEDING_TYPE_KEY = 'deleteSupplementaryFeedingType';

// Treatment
export const GET_TREATMENTS_KEY = 'treatments';
export const UPSERT_TREATMENT_KEY = 'upsertTreatment';
export const DELETE_TREATMENT_KEY = 'deleteTreatment';

// Disease
export const GET_DISEASES_KEY = 'diseases';
export const UPSERT_DISEASE_KEY = 'upsertDisease';
export const DELETE_DISEASE_KEY = 'deleteDisease';

// ApplicationMethod
export const GET_APPLICATION_METHODS_KEY = 'applicationMethods';
export const UPSERT_APPLICATION_METHOD_KEY = 'upsertApplicationMethod';
export const DELETE_APPLICATION_METHOD_KEY = 'deleteApplicationMethod';

// RecoveryFeedback
export const GET_RECOVERY_FEEDBACKS_KEY = 'recoveryFeedbacks';
export const UPSERT_RECOVERY_FEEDBACK_KEY = 'upsertRecoveryFeedback';
export const DELETE_RECOVERY_FEEDBACK_KEY = 'deleteRecoveryFeedback';

// Reason
export const GET_REASONS_KEY = 'reasons';
export const UPSERT_REASON_KEY = 'upsertReason';
export const DELETE_REASON_KEY = 'deleteReason';

// GrassLevel
export const GET_GRASS_LEVELS_KEY = 'grassLevels';
export const UPSERT_GRASS_LEVEL_KEY = 'upsertGrassLevel';
export const DELETE_GRASS_LEVEL_KEY = 'deleteGrassLevel';

// ReportConfig     
export const GET_REPORT_CONFIG_KEY = 'reportConfig';
export const GET_REPORT_CONFIGS_KEY = 'reportConfigs';
export const UPSERT_REPORT_CONFIG_KEY = 'upsertReportConfig';
export const GENERATE_REPORT_CONFIG_REVISION_KEY = 'generateNewReportConfigRevision';
export const DELETE_REPORT_CONFIG_KEY = 'deleteReportConfig';

// ReportConfigQuestion
export const GET_REPORT_CONFIG_QUESTIONS_KEY = 'reportConfigQuestions';
export const UPSERT_REPORT_CONFIG_QUESTION_KEY = 'upsertReportConfigQuestion';
export const DELETE_REPORT_CONFIG_QUESTION_KEY = 'deleteReportConfigQuestion';

// ReportConfigSection
export const GET_REPORT_CONFIG_SECTIONS_KEY = 'reportConfigSections';
export const UPSERT_REPORT_CONFIG_SECTION_KEY = 'upsertReportConfigSection';
export const DELETE_REPORT_CONFIG_SECTION_KEY = 'deleteReportConfigSection';

// Master Data View
export const GET_MASTER_DATA_VIEW = 'masterDataView';


/*================================================================================================================
*                                             Dev Tools Master Data
* ==============================================================================================================*/

// Contact Us
export const GET_CONTACTS_KEY = 'contacts';
export const UPSERT_CONTACT_KEY = 'upsertContact';
export const DELETE_CONTACT_KEY = 'deleteContact';

// News
export const GET_NEWS_KEY = 'news';
export const UPSERT_NEWS_KEY = 'upsertNews';
export const DELETE_NEWS_KEY = 'deleteNews';

// FrequentlyAskedQuestion
export const GET_FREQUENTLY_ASKED_QUESTIONS_KEY = 'frequentlyAskedQuestion';
export const UPSERT_FREQUENTLY_ASKED_QUESTION_KEY = 'upsertfrequentlyAskedQuestion';
export const DELETE_FREQUENTLY_ASKED_QUESTION_KEY = 'deletefrequentlyAskedQuestion';

// Logging
export const GET_LOGS_KEY = 'logs';

/*================================================================================================================
*                                                    Fresh Service
* ==============================================================================================================*/

export const UPSERT_FRESH_SERVICE_TICKET = 'upsertFreshServiceTicket';

/*================================================================================================================
*                                                  Transactional
* ==============================================================================================================*/


// Cattle
export const GET_CATTLES_KEY = 'cattles';
export const UPSERT_CATTLE_KEY = 'upsertCattle';
export const DELETE_CATTLE_KEY = 'deleteCattle';

// Cattle Treatment Case
export const GET_CATTLE_TREATMENT_CASES_KEY = 'cattleTreatmentCases';
export const DELETE_CATTLE_TREATMENT_CASE_KEY = 'deleteCattleTreatmentCase';

// Cattle Follow Up
export const GET_CATTLE_FOLLOW_UPS_KEY = 'CattleFollowUps';
export const DELETE_CATTLE_FOLLOW_UP_CASE_KEY = 'deleteCattleFollowUp';

// ReportAction
export const GET_REPORT_ACTIONS_KEY = 'reportActions';
export const GET_REPORT_ACTION_STATUS_VIEW_KEY = 'reportActionStatusView';
export const UPSERT_REPORT_ACTION_KEY = 'upsertReportAction';
export const DELETE_REPORT_ACTION_KEY = 'deleteReportAction';

// ReportActionAnswer
export const GET_REPORT_ACTION_ANSWERS_KEY = 'reportActionAnswers';
export const UPSERT_REPORT_ACTION_ANSWER_KEY = 'upsertReportActionAnswer';
export const DELETE_REPORT_ACTION_ANSWER_KEY = 'deleteReportActionAnswer';