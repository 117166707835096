import React, { Suspense, lazy } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { useAppSelector } from '../../@types/redux';
import { IBaseUserRight, Loader, NoRightsScreen, Screen } from '@zz2/zz2-ui';
import { RightGrantsEnum } from '../../@types/enum/rightGrantsEnum';

const PrivateRoute = (props : RouteProps) : JSX.Element => {
    const session = useAppSelector(x => x.auth.session);
    const { component, ...rest } = props;

    const hasMenuRight = (url : string) : boolean => {
        if (!session) return false;
        if (session.user.userRights.length < 1) return false;

        const userRights : Array<IBaseUserRight> = session.user.userRights;
        if (userRights.length < 1) {
            return false;
        } else {
            const urlSections = url.split('/'); 
            const firstPartition = urlSections.slice(0, -1).join('/');
            const filteredUserRights : Array<IBaseUserRight> = userRights.filter(x => !!x.isActive && x.right.url === (urlSections[urlSections.length - 1].includes('Id') 
                ? firstPartition
                : url
            ));

            if (filteredUserRights.length > 0 && filteredUserRights.some(x => x.rightGrantLevel === RightGrantsEnum.View)) {
                return true;
            } else {
                return false;
            }
        }
    };

    const render = (routerProps : RouteComponentProps<any>) : React.ReactNode => {
        if (session) {
            if (!component) return;

            const Component = component;
            if (!hasMenuRight(routerProps.match.url)) {
                return <NoRightsScreen openSupportTicket={() => console.log('test')}/>;
            }
            
            return <Component {...routerProps} />;
        }

        return <Redirect to={{ pathname: '/login', state: { from: routerProps.location } }} />;
    };

    return (<Route {...rest} render={render} />);
};

{/* Route Imports */}
const Home = lazy(() => import('../home/Home'));

{/* Master Data */}
const MasterDataHome = React.lazy(() => import('../masterData/masterDataHome'));
const ApplicationMethodManager = lazy(() => import('../masterData/applicationMethod/ApplicationMethodManager'));
const CampManager = lazy(() => import('../masterData/camp/CampManager'));
const CommentTypeManager = lazy(() => import('../masterData/commentType/CommentTypeManager'));
const DiseaseManager = lazy(() => import('../masterData/disease/DiseaseManager'));
const EyeColourManager = lazy(() => import('../masterData/eyeColour/EyeColourManager'));
const GenderManager = lazy(() => import('../masterData/gender/GenderManager'));
const GrassLevelManager = lazy(() => import('../masterData/grassLevel/GrassLevelManager'));
const HerdManager = lazy(() => import('../masterData/herd/HerdManager'));
const ReasonManager = lazy(() => import('../masterData/reason/ReasonManager'));
const RecoveryFeedbackManager = lazy(() => import('../masterData/recoveryFeedback/RecoveryFeedbackManager'));
const RegionManager = lazy(() => import('../masterData/region/RegionManager'));
const SupplementaryFeedingTypeManager = lazy(() => import('../masterData/supplementaryFeedingType/SupplementaryFeedingTypeManager'));
const TickLevelManager = lazy(() => import('../masterData/tickLevel/TickLevelManager'));
const TickTypeManager = lazy(() => import('../masterData/tickType/TickTypeManager'));
const TreatmentManager = lazy(() => import('../masterData/treatment/TreatmentManager'));

{/* Report Config */}
const ReportConfigManager = lazy(() => import('../reportConfig/ReportConfigManager'));
const ReportConfigBuilder = lazy(() => import('../reportConfig/reportConfigBuilder/ReportConfigBuilder'));

{/* Report Action */}
const ReportActionManager = lazy(() => import('../reportAction/ReportActionManager'));
const ReportActionViewer = lazy(() => import('../reportAction/ReportActionViewer'));

{/* Cattle */}
const CattleManager = lazy(() => import('../cattle/CattleManager'));

{/* Cattle Treatment */}
const CattleTreatmentCaseOverview = lazy(() => import('../cattleTreatment/CattleTreatmentCaseOverview'));

{/* Rights */}
const UserManager = lazy(() => import('../right/userManagement/UserManager'));
const RightsManagement = lazy(() => import('../right/rightsManagement/RightsManager'));

{/* Development Tools*/}
const LogManager = React.lazy(() => import('../logging/LogManager'));
const ContactUsManager = React.lazy(() => import('../masterData/contactUs/ContactUsManager'));
const NewsManager = React.lazy(() => import('../masterData/news/NewsManager'));
const FrequentlyAskedQuestionManager = React.lazy(() => import('../masterData/frequentlyAskedQuestion/FrequentlyAskedQuestionManager'));


const Routes = () : React.ReactElement => {
    return (
        <Suspense fallback={<Screen><Loader/></Screen>}>
            <Switch>
                <Route
                    path={'/'} exact
                    render={() => <Redirect from={'/'} to={{ pathname: '/home' }} />}
                />
                
                <PrivateRoute exact path='/home' component={Home} />

                {/* MasterData */}
                <PrivateRoute exact path='/masterData' component={MasterDataHome} />
                <PrivateRoute exact path='/masterData/camp' component={CampManager} />
                <PrivateRoute exact path='/masterData/gender' component={GenderManager} />
                <PrivateRoute exact path='/masterData/commentType' component={CommentTypeManager} />
                <PrivateRoute exact path='/masterData/region' component={RegionManager} />
                <PrivateRoute exact path='/masterData/herd' component={HerdManager} />
                <PrivateRoute exact path='/masterData/eyeColour' component={EyeColourManager} />
                <PrivateRoute exact path='/masterData/tickType' component={TickTypeManager} />
                <PrivateRoute exact path='/masterData/tickLevel' component={TickLevelManager} />
                <PrivateRoute exact path='/masterData/supplementaryFeedingType' component={SupplementaryFeedingTypeManager} />
                <PrivateRoute exact path='/masterData/treatment' component={TreatmentManager} />
                <PrivateRoute exact path='/masterData/disease' component={DiseaseManager} />
                <PrivateRoute exact path='/masterData/applicationMethod' component={ApplicationMethodManager} />
                <PrivateRoute exact path='/masterData/recoveryFeedback' component={RecoveryFeedbackManager} />
                <PrivateRoute exact path='/masterData/reason' component={ReasonManager} />
                <PrivateRoute exact path='/masterData/grassLevel' component={GrassLevelManager} />

                {/* Cattle*/}
                <PrivateRoute exact path='/cattle/cattleManagement' component={CattleManager} />

                {/* Cattle Treatment */}
                <PrivateRoute exact path='/cattle/treatmentOverview' component={CattleTreatmentCaseOverview} />

                {/* Report Config */}
                <PrivateRoute exact path='/report/reportConfigManager' component={ReportConfigManager} />
                <PrivateRoute exact path='/report/reportConfigManager/:reportConfigId?' component={ReportConfigBuilder} />

                {/* Report Action */}
                <PrivateRoute exact path='/report/reportActionManager' component={ReportActionManager} />  
                <PrivateRoute exact path='/report/reportActionManager/:reportActionId?' component={ReportActionViewer} />

                {/* Rights */}
                <PrivateRoute exact path='/rights/userManagement' component={UserManager} />
                <PrivateRoute exact path='/rights/rightsManagement' component={RightsManagement} />
                
                {/* Development Tools*/}
                <PrivateRoute exact path='/development/tools' component={LogManager} />
                <PrivateRoute exact path='/development/contactUsManager' component={ContactUsManager} />
                <PrivateRoute exact path='/development/newsManager' component={NewsManager} />
                <PrivateRoute exact path='/development/faqManager' component={FrequentlyAskedQuestionManager} />

            </Switch>
        </Suspense>
    );
};

export default Routes;