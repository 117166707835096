import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import EnvHelper from './service/helper/envHelper';
import makeStyles from '@mui/styles/makeStyles';
import { SNACKBAR_WIDTH } from './appConstants';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import store from './store';
import { blue, orange, red } from '@mui/material/colors';
import App from './App';
import materialTheme from './style/materialTheme';

const snackBarProviderAnchor : SnackbarOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const AppRoot = () : React.ReactElement => {

    document.title = EnvHelper.getEnvName();

    const useStyles = makeStyles(() => ({
        success: { backgroundColor: materialTheme.palette.primary.main, width: SNACKBAR_WIDTH },
        error: { backgroundColor: red[600], width: SNACKBAR_WIDTH },
        warning: { backgroundColor: orange[600], width: SNACKBAR_WIDTH },
        info: { backgroundColor: blue[600], width: SNACKBAR_WIDTH },
    }));

    const classes = useStyles();

    const snackBarProviderClasses = {
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
    };


    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={store}>
                <HashRouter>
                    <SnackbarProvider 
                        maxSnack={4}
                        anchorOrigin={snackBarProviderAnchor}
                        classes={snackBarProviderClasses}>
                        <App />
                    </SnackbarProvider>
                </HashRouter>
            </Provider>
        </LocalizationProvider>
    );
};

const rootDomElement = document.getElementById('root');

if (rootDomElement) {
    ReactDOM.render(<AppRoot />, rootDomElement);
}
