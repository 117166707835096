import React from 'react';
import { IFreshServiceTicketFormValues } from '../../@types/model/freshService/freshServiceTicketModels';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Button } from '@mui/material';
import { ControlledMuiAutoCompleteSelect, IOptionType, PillButton } from '@zz2/zz2-ui';
import { useForm } from 'react-hook-form';
import FreshServiceTicketModelHelper from '../../@types/model/freshService/freshServiceTicketModelHelper';

interface IFreshServiceTicketFormProps {
    initialValues : IFreshServiceTicketFormValues;
    onSubmit : (values : IFreshServiceTicketFormValues) => void;
    onCancel : () => void;

    userOptions : Array<IOptionType>;
}

const FreshServiceTicketForm = (props : IFreshServiceTicketFormProps) : React.ReactElement => {
    const schema = FreshServiceTicketModelHelper.formSchema();

    const { register, handleSubmit, control, formState: { errors, isValid } } = useForm<IFreshServiceTicketFormValues>({
        defaultValues: props.initialValues,
        resolver: yupResolver(schema),
        mode: 'onChange'
    });

    return (
        <>
            <form onSubmit={handleSubmit(props.onSubmit)}>
                <div className={'fdc p30 pt15 pb0'}>
                    <div className={'fdc wfill pt10 jcc aic mb20'}>
                        <div className={'wfill mb15'}>
                            <TextField
                                {...register('systemName')}
                                label={'System Name'}
                                className={'wfill'}
                                error={!!errors.systemName}
                                helperText={errors.systemName ? errors.systemName.message : ''}
                                disabled={true}
                            />
                        </div>
                        <div className={'wfill mb15'}>
                            <TextField
                                {...register('systemVersion')}
                                label={'System Version'}
                                className={'wfill'}
                                error={!!errors.systemVersion}
                                helperText={errors.systemVersion ? errors.systemVersion.message : ''}
                                disabled={true}
                            />
                        </div>
                        <div className={'wfill mb15'}>
                            <TextField
                                {...register('location')}
                                label={'Location'}
                                className={'wfill'}
                                error={!!errors.location}
                                helperText={errors.location ? errors.location.message : ''}
                                disabled={true}
                            />
                        </div>
                        <div className={'wfill mb15'}>
                            <TextField
                                {...register('name')}
                                label={'Name'}
                                className={'wfill'}
                                error={!!errors.name}
                                helperText={errors.name ? errors.name.message : ''}
                                disabled={true}
                            />
                        </div>
                        <div className={'wfill mb15'}>
                            <TextField
                                {...register('email')}
                                label={'Email'}
                                className={'wfill'}
                                error={!!errors.email}
                                helperText={errors.email ? errors.email.message : ''}
                                disabled={true}
                            />
                        </div>
                        <div className={'wfill mb15'}>
                            <TextField
                                {...register('description')}
                                label={'Description'}
                                className={'wfill'}
                                multiline={true}
                                rows={4}
                                variant={'standard'}
                                error={!!errors.description}
                                helperText={errors.description ? errors.description.message : ''}
                            />
                        </div>
                        <ControlledMuiAutoCompleteSelect 
                            name='referencedUser'
                            control={control}
                            label={'Manager'} 
                            className={'wfill mb20'}
                            defaultValues={props.initialValues.referencedUser ?? null}
                            options={props.userOptions}
                        />
                    </div>
                    <div className={'fdr flx1 aic jcfe mb20'}>
                        <Button
                            className={'cpd mr20'}
                            onClick={props.onCancel}
                            type={'reset'}>
                            CANCEL
                        </Button>
                        <PillButton
                            className={'pl30 pr30'}
                            text={'SAVE'}
                            size={'small'}
                            type={'submit'}
                            color={'secondary'}
                            disabled={!isValid}
                        />
                    </div>
                </div>
            </form>
            <DevTool control={control} />
        </>
    );
};

export default FreshServiceTicketForm;
